import React from 'react';
import PropTypes from 'prop-types';
import {
  Form, Grid, Row, Col,
} from 'react-bootstrap';

class AddToDo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      todoItems: [],
      text: '',
      textClass: 'simple',
      showDiv: '',
      showToEdit: 'hidden',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // To create a new to-do :
  handleChange(e, key) {
    const newState = {};
    newState[key] = e.target.value;
    this.setState(newState);
  }

  handleSubmit(e, user, fb) {
    e.preventDefault();
    const { day, getPrettyDate, getPrettyTime } = this.props;
    const {
      text, textClass, showToEdit, showDiv,
    } = this.state;
    if (!text.length) {
      return;
    }
    const newItem = {
      text,
      date: getPrettyDate(new Date()),
      time: getPrettyTime(new Date()),
      textClass,
      showToEdit,
      showDiv,
    };
    fb.db.ref(`todoItems/${user.uid}/${day}/`).push(newItem);
    this.setState({ text: '' });
  }

  render() {
    const { todoItems, text } = this.state;
    const { firebase, authUser } = this.props;
    return (
      <Form onSubmit={(e) => this.handleSubmit(e, authUser, firebase)} className="w-100" style={{ maxWidth: '100%' }}>
        <Grid>
          <Row className="pt5 itemRow">
            <Col md={1}>
              <h5 className="tr">
                         New:
              </h5>
            </Col>
            <Col md={5} className="pl4">
              <input
                id="new-todo"
                className="inputItem"
                onChange={(e) => this.handleChange(e, 'text')}
                value={text}
              />
            </Col>
            <Col md={3}>
              <button
                className="mySubmitButton"
                type="submit"
              >
                {'Add task'}
              </button>
            </Col>
            <Col md={3} />
          </Row>
        </Grid>
      </Form>

    );
  }
}

AddToDo.propTypes = {
  firebase: PropTypes.objectOf(PropTypes.object),
  authUser: PropTypes.objectOf(PropTypes.object),
  day: PropTypes.string,
  getPrettyDate: PropTypes.func,
  getPrettyTime: PropTypes.func,
};

AddToDo.defaultProps = {
  firebase: {},
  authUser: {},
  day: 'tdy',
  getPrettyDate: () => {},
  getPrettyTime: () => {},
};

export default AddToDo;
