import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';

import Navigation from '../Navigation';
import LandingPage from '../Landing/index';
import IntroPage from '../Intro/index';
import IntroPageForSignedIn from '../Intro/introForSignedIn';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Home';
import AccountPage from '../Account';
import AdminPage from '../Admin';
import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';


const App = () => (
  <Router>
    <div className="app">
      <div className="appWidth anyBackground">
        <Route exact path={ROUTES.LANDING} component={LandingPage} />
        <Route exact path={ROUTES.INTRO} component={IntroPage} />
        <Route exact path={ROUTES.INTRO_AUTH} component={IntroPageForSignedIn} />
        <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
        <Route path={ROUTES.SIGN_IN} component={SignInPage} />
        <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
        <Route path={ROUTES.HOME} component={HomePage} />
        <Route path={ROUTES.ACCOUNT} component={AccountPage} />
        <Route path={ROUTES.ADMIN} component={AdminPage} />
      </div>
      <Navigation />
    </div>
  </Router>
);

export default withAuthentication(App);
