import { applyMiddleware, combineReducers, createStore } from 'redux';
import logger from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import initState from './initialState';
import todoListReducer from './reducers/todoListReducer';
import todoExampleReducer from './reducers/todoExampleReducer';

export default function configureStore(initialState = initState) {
  return createStore(
    combineReducers({
      todoListReducer,
      todoExampleReducer,
    }),
    initialState,
    composeWithDevTools(applyMiddleware(logger)),
  );
}
