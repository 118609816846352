import React, { Component } from 'react';
import { Col, Row, Grid } from 'react-bootstrap';
import { withFirebase } from '../Firebase';
import me from '../../img/for_todolist_site_full.png';

class AdminPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      users: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase.users().on('value', (snapshot) => {
      const usersObject = snapshot.val();

      const usersList = Object.keys(usersObject).map((key) => ({
        ...usersObject[key],
        uid: key,
      }));

      this.setState({
        users: usersList,
        loading: false,
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }

  render() {
    // const { users, loading } = this.state;

    return (
      <Grid>
        <Row className="tc pt4">
          <Col md={7}>
            <h3 className="tc pt4">Nice to meet you! I am Mariia.</h3>
            <h2 className="tc pt4">Thank you for using my app.</h2>
            <h4 className="tc pt4">I am not a professional web developer and I create web apps as a hobby.</h4>
            {/* {loading && <div>Loading ...</div>} */}
            {/* <UserList users={users} /> */}
          </Col>
          <Col md={5}>
            <img src={me} />
          </Col>
        </Row>
        <Row>
          <h4 className="tc pt2">
            {'You can find more about me on my portfolio web-page '}
            <a href="https://nesterova.fr/">nesterova.fr</a>
          </h4>
        </Row>
      </Grid>
    );
  }
}
//
// const UserList = ({ users }) => (
//   <Grid>
//     <Row className="myRow">
//       <Col md={4} className="tc">ID: </Col>
//       <Col md={4} className="tc">E-Mail: </Col>
//       <Col md={4} className="tl">Username: </Col>
//     </Row>
//     {users.map((user) => (
//       <Row key={user.uid}>
//         <Col md={4} className="tc">{user.uid}</Col>
//         <Col md={4} className="tc">{user.email}</Col>
//         <Col md={4} className="tl">{user.username}</Col>
//       </Row>
//     ))}
//   </Grid>
// );

export default withFirebase(AdminPage);
