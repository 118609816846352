const reduxGlossary = {
  actionName: {
    todoList: {
      oneCompleted: 'TODOLIST_ONE_COMPLETED_CHANGED',
    },
    todoExample: {
      addTodo: 'TODOEXAMPLE_ADD_TODO',
      deleteTodo: 'TODOEXAMPLE_DELETE_TODO',
      moveTodo: 'TODOEXAMPLE_MOVE_TODO',
      doneTodo: 'TODOEXAMPLE_DONE_TODO',
      editTodo: 'TODOEXAMPLE_EDIT_TODO',
      editedTodo: 'TODOEXAMPLE_EDITED_TODO',
    },
  },
};

export default reduxGlossary;
