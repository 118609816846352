import reduxGlossary from '../glossary';

const todoExampleActions = {};

todoExampleActions.addTodo = (dispatch, todo) => {
  dispatch({
    type: reduxGlossary.actionName.todoExample.addTodo,
    payload: todo,
  });
};
todoExampleActions.deleteTodo = (dispatch, list, i) => {
  dispatch({
    type: reduxGlossary.actionName.todoExample.deleteTodo,
    payload: {
      index: i,
      listId: list,
    },
  });
};
todoExampleActions.moveTodo = (dispatch, list1, todoItem, list2, i) => {
  dispatch({
    type: reduxGlossary.actionName.todoExample.moveTodo,
    payload: {
      list1Id: list1,
      todo: todoItem,
      list2Id: list2,
      index: i,
    },
  });
};
todoExampleActions.doneTodo = (dispatch, list, i) => {
  dispatch({
    type: reduxGlossary.actionName.todoExample.doneTodo,
    payload: {
      index: i,
      listId: list,
    },
  });
};
todoExampleActions.editTodo = (dispatch, list, i) => {
  dispatch({
    type: reduxGlossary.actionName.todoExample.editTodo,
    payload: {
      index: i,
      listId: list,
    },
  });
};
todoExampleActions.editedTodo = (dispatch, list, i, newText) => {
  dispatch({
    type: reduxGlossary.actionName.todoExample.editedTodo,
    payload: {
      index: i,
      listId: list,
      text: newText,
    },
  });
};

export default todoExampleActions;
