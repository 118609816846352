import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Row, Col,
} from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import Modal from 'react-responsive-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faBoxOpen } from '@fortawesome/free-solid-svg-icons';

class History extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      open: false,
      archiveDates: [],
      archiveDay: '',
      show: 'hidden',
      history: [],
      oldItems: [],
      buttonShown: '',
      buttonHidden: 'hidden',
    };

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentWillMount() {
    const { firebase, authUser } = this.props;
    const archiveDatesRef = firebase.db.ref(`todoItems/${authUser.uid}/archive/`).orderByKey();
    archiveDatesRef.on('value', (archive) => {
      const newState = [];
      const onlyDates = [];
      const history = archive.val();
      for (const item in history) {
        newState.push({
          id: item,
          text: history[item].text,
          date: history[item].date,
        });
        onlyDates.push(history[item].date);
      }
      const uniqueDates = onlyDates.filter((item, i) => onlyDates.indexOf(item) === i);
      const sortedByDate = uniqueDates.sort();
      let oldDates = [];
      const toRemove = sortedByDate.length - 10;
      if (toRemove > 0) {
        oldDates = sortedByDate.splice(toRemove, 10);
      } else {
        oldDates = sortedByDate;
      }
      this.setState({ archiveDates: oldDates, history: newState });
    });
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleShow() {
    this.setState({ open: true });
  }

  openHistory(archiveDate) {
    const allHistory = this.state.history;
    const newState = [];
    for (const item in allHistory) {
      if (allHistory[item].date === archiveDate) {
        newState.push(allHistory[item]);
      }
      this.setState({ oldItems: newState });
    }
    this.setState({ archiveDay: archiveDate, open: true });
  }


  render() {
    const {
      open, archiveDates, show, archiveDay, oldItems,
    } = this.state;
    const { firebase, authUser } = this.props;
    return (
      <div
        className="pt-3 pb-3"
        onMouseOver={() => { this.setState({ buttonHidden: '', buttonShown: 'hidden' }); }}
        onMouseOut={() => { this.setState({ buttonHidden: 'hidden', buttonShown: '' }); }}
      >
        <button
          data-tip
          data-for="tooltipHistory"
          onClick={() => {
            (show === 'hidden') ? this.setState({ show: 'dropped' }) : this.setState({ show: 'hidden' });
          }}
          className="myDropButton"
          style={{ color: 'black' }}
        >
          <FontAwesomeIcon className={this.state.buttonShown} icon={faBox} />
          <FontAwesomeIcon className={this.state.buttonHidden} icon={faBoxOpen} />
        </button>
        <ReactTooltip id="tooltipHistory">
          Press to see 10 last days of your completed tasks.
        </ReactTooltip>
        <div
          className={show}
          onMouseLeave={() => { this.setState({ show: 'hidden' }); }}
        >
          <div className="">
            {archiveDates.map((archiveDate, index) => (
              <a
                key={index + 1.1}
                eventKey={archiveDate}
                className=" "
                onClick={() => {
                  this.openHistory(archiveDate);
                }}
              >
                {archiveDate}
              </a>
            ))}
          </div>
        </div>

        <Modal
          open={open}
          onClose={this.handleClose}
          little
        >
          <div
            className="modalForHistory"
          >
            <h3 className="historyText pb5">
              {'Completed on '}
              {archiveDay}
            </h3>
            {
              oldItems.map((oldItem, index) => (
                <Grid key={index * 1.8}>
                  <Row className="completed" style={{ display: 'flex' }}>
                    <Col sm={2} className="tc historyText">
                      {index + 1}
                    </Col>
                    <Col sm={8} className="tl historyText">
                      <div>
                        {oldItem.text}
                      </div>
                    </Col>
                    <Col sm={2} className="tr historyText">
                      <button
                        className="deleteButtonSmall"
                        onClick={() => { firebase.db.ref(`todoItems/${authUser.uid}/archive/${oldItem.id}/`).remove(); oldItems.splice(index, 1); }}
                      >
                        X
                      </button>
                    </Col>
                  </Row>
                </Grid>
              ))
            }
          </div>
        </Modal>
      </div>
    );
  }
}

History.propTypes = {
  firebase: PropTypes.objectOf(PropTypes.object),
  authUser: PropTypes.objectOf(PropTypes.object),
};

History.defaultProps = {
  firebase: {},
  authUser: {},
};

export default History;
