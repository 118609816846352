import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { FirebaseContext } from '../Firebase';
import { withFirebase } from '../Firebase';

import * as ROUTES from '../../constants/routes';

const SignUpPage = () => (
    <div className="signUpFormBackground">
        <SignUpForm />
    </div>
);


const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: null,
};

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);
    
    this.state = { ...INITIAL_STATE };
  }

  
  onSubmit = event => {
    const { username, email, passwordOne } = this.state;
  
    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        return this.props.firebase
          .user(authUser.user.uid)
          .set({
            username,
            email,
          });
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
  
      })
      .catch(error => {
        this.setState({ error });
      });
  
    event.preventDefault();
  };
  
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  
  };
  
  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      error,
    } = this.state;
    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      username === '';
  
    return (
        <div className="signUpFormBase">
            <div className="header">Sign Up</div>
            <form onSubmit={this.onSubmit}>
                <div className="myRow">
                    <span className="myCol-25 textRight alignM">Username:</span>
                    <span className="myCol-75 alignM">
            <input
                name="username"
                className="inputItem"
                type="text"
                placeholder="New username"
                value={username}
                onChange={this.onChange}/>
          </span>
                </div>
                <div className="myRow">
                    <span className="myCol-25 textRight alignM">Email address:</span>
                    <span className="myCol-75 alignM">
            <input
                name="email"
                className="inputItem"
                type="email"
                placeholder="Your email"
                value={email}
                onChange={this.onChange}/>
          </span>
                </div>
                <div className="myRow">
                    <span className="myCol-25 textRight alignM">Password:</span>
                    <span className="myCol-75 alignM">
            <input
                name="passwordOne"
                className="inputItem"
                type="password"
                placeholder="Create"
                value={passwordOne}
                onChange={this.onChange}/>
          </span>
                </div>
                <div className="myRow">
                    <span className="myCol-25 textRight alignM">Password:</span>
                    <span className="myCol-75 alignM">
            <input
                name="passwordTwo"
                className="inputItem"
                type="password"
                placeholder="Repeat"
                value={passwordTwo}
                onChange={this.onChange}/>
            </span>
                </div>
                <div className="myRow textCenter">
                    <button
                        className="mySubmitButton pointer"
                        type="submit"
                        disabled={isInvalid}>
                        SUBMIT
                    </button>
                </div>
            </form>
            <div className="myRow textCenter">
                {error &&
                <div>
                    <div>{error.message}</div>
                    <div>Have account? <Link to={ROUTES.SIGN_IN}>Sign in</Link> here.</div>
                </div>}
            </div>
        </div>
    );
  }
}


const SignUpLink = () => (
    <div className="myRow textCenter">
        Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up now!</Link>
    </div>
);

const SignUpForm = compose(
  withRouter,
  withFirebase,
)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink };