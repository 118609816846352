import React from 'react';
import { Grid, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';


class LandingPage extends React.PureComponent {
  render() {
    return (
      <div>
        <Grid>
          <Row className="justify-content-center pt7 tc f-5">
                        WELCOME
              <span className="pl1" role="img" > 🐣 </span>
          </Row>
          <Row className="justify-content-center pt4 tc">
            <Link to={ROUTES.INTRO} className="link">
              <button className="mySubmitButton" >
                {'TEST THE APP'}
              </button>
            </Link>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default LandingPage;
