import React from 'react';
import { Link } from 'react-router-dom';
import SignOutButton from '../SignOut';
import { AuthUserContext } from '../Session';
import * as ROUTES from '../../constants/routes';

import './Navigation.css';

const Navigation = () => (
  <AuthUserContext.Consumer>
    {(authUser) => (authUser ? <NavigationAuth /> : <NavigationNonAuth />)}
  </AuthUserContext.Consumer>
);

const OneLink = (props) => {
  const { link, text } = props;
  return (
    <span className="myNavbarItem">
      <Link to={link}>
        <button className="myNavButton" style={{ width: 'max-content' }} >
          {text}
        </button>
      </Link>
    </span>
  );
};


const NavigationAuth = () => (
  <div className="appWidth myNavbar">
    <div className="navLeft">
      <OneLink link={ROUTES.INTRO_AUTH} text="ToDoIntro" />
      <OneLink link={ROUTES.HOME} text="Home" />
      <OneLink link={ROUTES.ACCOUNT} text="Account" />
    </div>
    <div className="navRight">
      <OneLink link={ROUTES.ADMIN} text="Admin 😉"/>
      <div className="myNavbarItem textRight">
        <SignOutButton />
      </div>
    </div>
  </div>
);

const NavigationNonAuth = () => (
  <div className="myNavbar">
    <span className="">
      <OneLink link={ROUTES.INTRO} text="ToDoIntro" />
      <OneLink link={ROUTES.SIGN_IN} text="Sign In" />
      <OneLink link={ROUTES.SIGN_UP} text="Sign Up" />
    </span>
  </div>
);

export default Navigation;
