import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { PasswordForgetLink } from '../PasswordForget';

import { SignUpLink } from '../SignUp';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const SignInPage = () => (
  <div className="signInFormBackground">
    <SignInForm />
  </div>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch((error) => {
        this.setState({ error });
      });

    event.preventDefault();
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === '' || email === '';

    return (
      <div className="signInFormBase">
        <div className="header">Sign In</div>
        <form onSubmit={this.onSubmit}>
          <div className="myRow">
            <span className="myCol-25 alignM textRight">Email:</span>
            <span className="myCol-75 alignM">
              <input
                name="email"
                className="inputItem"
                type="text"
                placeholder="Your email here"
                value={email}
                onChange={this.onChange}
              />
            </span>
          </div>
          <div className="myRow">
            <span className="myCol-25 alignM textRight">Password:</span>
            <span className="myCol-75 alignM">
              <input
                name="password"
                className="inputItem"
                type="password"
                placeholder="Your password here"
                value={password}
                onChange={this.onChange}
              />
            </span>
          </div>
          <div className="myRow textCenter">
            <button
              className="mySubmitButton pointer"
              type="submit"
              disabled={isInvalid}
            >
                        SUBMIT
            </button>
          </div>
          <div className="myRow textCenter">
            {error
                    && (
                    <div>
                      <div>{error.message}</div>
                      <div />
                    </div>
                    )}
          </div>
        </form>
        <PasswordForgetLink />
        <SignUpLink />

      </div>
    );
  }
}

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

export default SignInPage;

export { SignInForm };
