import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Row, FormControl, Form,
} from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import todoListActions from '../../redux/actions/todoListActions';

const mapStateToProps = (stateRedux) => ({
  ...stateRedux,
});

const mapDispatchToProps = (dispatch) => ({
  changeOneCompleted: (bool) => todoListActions.changeOneCompleted(dispatch, bool),
});

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      todoItems: [],
      newText: '',
    };

    this.done = this.done.bind(this);
    this.move = this.move.bind(this);
    this.editText = this.editText.bind(this);
    this.textEdited = this.textEdited.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {
    const {
      day, firebase, authUser, changeOneCompleted,
    } = this.props;
    const todoItemsRef = firebase.db.ref(`todoItems/${authUser.uid}/${day}/`).orderByKey();
    todoItemsRef.on('value', (todo) => {
      const todoItems = todo.val();
      const newState = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const todoItem in todoItems) {
        newState.push({
          id: todoItem,
          text: todoItems[todoItem].text,
          textClass: todoItems[todoItem].textClass,
          date: todoItems[todoItem].date,
          time: todoItems[todoItem].time,
          showDiv: todoItems[todoItem].showDiv,
          showToEdit: todoItems[todoItem].showToEdit,
        });
        if (todoItems[todoItem].textClass === 'completed') {
          changeOneCompleted(true);
        }
      }
      this.setState({ todoItems: newState });
    });
  }

  handleChange(e, key) {
    const newState = {};
    newState[key] = e.target.value;
    this.setState(newState);
  }

  // functions to operate with todos :

  deleteItem(id, fb, user) {
    const { day } = this.props;
    fb.db.ref(`todoItems/${user.uid}/${day}/${id}/`).remove();
  }

  move(id, fb, user) {
    const movedItem = {};
    const { todoItems } = this.state;
    const { day, getPrettyDate, getPrettyTime } = this.props;
    for (let i = 0; i < todoItems.length; i++) {
      if (todoItems[i].id === id) {
        movedItem.text = todoItems[i].text;
        movedItem.date = getPrettyDate(new Date());
        movedItem.time = getPrettyTime(new Date());
        movedItem.textClass = todoItems[i].textClass;
        movedItem.showToEdit = todoItems[i].showToEdit;
        movedItem.showDiv = todoItems[i].showDiv;
        fb.db.ref(`todoItems/${user.uid}/${(day === 'tdy') ? 'tmr' : 'tdy'}/`).push(movedItem);
        this.deleteItem(id, fb, user);
      }
    }
  }

  done(id, fb, user) {
    const ups = {};
    const { todoItems } = this.state;
    const { day, changeOneCompleted } = this.props;
    for (let i = 0; i < todoItems.length; i++) {
      if (todoItems[i].id === id) {
        if (todoItems[i].textClass === 'simple') { ups['/textClass'] = 'completed'; } else { ups['/textClass'] = 'simple'; }
        fb.db.ref(`/todoItems/${user.uid}/${day}/${id}`).update(ups);
      }
    }
    changeOneCompleted(true);
  }

  editText(id, fb, user) {
    const ups = {};
    const { todoItems } = this.state;
    const { day } = this.props;
    for (let i = 0; i < todoItems.length; i++) {
      if (todoItems[i].id === id) {
        ups['/showDiv'] = 'hidden';
        ups['/showToEdit'] = 'text';
        fb.db.ref(`/todoItems/${user.uid}/${day}/${id}`).update(ups);
        this.setState({ newText: todoItems[i].text });
      }
    }
  }

  textEdited(id, fb, user) {
    const ups = {};
    const { todoItems, newText } = this.state;
    const { day } = this.props;
    for (let i = 0; i < todoItems.length; i++) {
      if (todoItems[i].id === id) {
        ups['/text'] = newText;
        ups['/showDiv'] = '';
        ups['/showToEdit'] = 'hidden';
      }
      fb.db.ref(`/todoItems/${user.uid}/${day}/${id}`).update(ups);
    }
  }

  render() {
    const { todoItems, newText } = this.state;
    const { firebase, authUser, hidden } = this.props;
    return (
      todoItems.map((todoItem, index) => (

        <Grid key={todoItem.id} className={todoItem.textClass}>
          <Row className="itemRow">
            <button
              onClick={() => this.move(todoItem.id, firebase, authUser)}
              className="moveButton moveButtonLeft"
              hidden={!hidden}
            >
              {'❰'}
            </button>
            <button
              data-tip
              data-for="tooltipCompleted"
              onClick={() => this.done(todoItem.id, firebase, authUser)}
              className="listButton"
            >
              {index + 1}
            </button>
            <ReactTooltip id="tooltipCompleted">
              Press to if the task is completed.
            </ReactTooltip>
            <div className="itemText">
              <div
                data-tip
                data-for="tooltipCorrect"
                onDoubleClick={() => this.editText(todoItem.id, firebase, authUser)}
                className={todoItem.showDiv}
              >
                {todoItem.text}
              </div>
            </div>
            <div
              className={`todoChangeForm ${todoItem.showToEdit}`}
              onMouseLeave={() => { this.textEdited(todoItem.id, firebase, authUser); }}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  this.textEdited(todoItem.id, firebase, authUser);
                }
              }}
            >
              <Form className="itemText ">
                <FormControl
                  id={(todoItems.length / 123).toString()}
                  type="text"
                  value={newText}
                  onChange={(e) => this.handleChange(e, 'newText')}
                />
              </Form>
            </div>
            <ReactTooltip id="tooltipCorrect">
              Double-click to correct the task.
            </ReactTooltip>
            <button
              data-tip
              data-for="tooltipDelete"
              onClick={() => this.deleteItem(todoItem.id, firebase, authUser)}
              className="deleteButton"
            >
                X
            </button>
            <ReactTooltip id="tooltipDelete">
              Press to delete the task.
            </ReactTooltip>
            <button
              onClick={() => this.move(todoItem.id, firebase, authUser)}
              className="moveButton moveButtonRight"
              hidden={hidden}
            >
              {'❱'}
            </button>
          </Row>
        </Grid>
      ))
    );
  }
}

List.propTypes = {
  firebase: PropTypes.objectOf(PropTypes.object),
  authUser: PropTypes.objectOf(PropTypes.object),
  day: PropTypes.string,
  getPrettyDate: PropTypes.func,
  getPrettyTime: PropTypes.func,
  hidden: PropTypes.bool,
  changeOneCompleted: PropTypes.func,
};

List.defaultProps = {
  firebase: {},
  authUser: {},
  day: 'tdy',
  getPrettyDate: () => {},
  getPrettyTime: () => {},
  hidden: true,
  changeOneCompleted: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
