import reduxGlossary from '../glossary';

const todoListActions = {};

todoListActions.changeOneCompleted = (dispatch, bool) => {
  dispatch({
    type: reduxGlossary.actionName.todoList.oneCompleted,
    payload: bool,
  });
};

export default todoListActions;
