

export default {
  todoListReducer: {
    oneCompleted: false,
  },
  todoExampleReducer: {
    todoItemsTdy: [],
    todoItemsTmr: [],
  },
};
