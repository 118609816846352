import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import { faBox, faShare } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-responsive-modal';
import { connect } from 'react-redux';
import todoListActions from '../../redux/actions/todoListActions';

const mapStateToProps = (stateRedux) => ({
  ...stateRedux,
});

const mapDispatchToProps = (dispatch) => ({
  changeOneCompleted: (bool) => todoListActions.changeOneCompleted(dispatch, bool),
});

class Archive extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      todoTdy: [],
      todoTmr: [],
      open: false,
      text: '',
    };

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentWillMount() {
    const { firebase, authUser } = this.props;
    const todoItemsTdy = firebase.db.ref(`todoItems/${authUser.uid}/tdy/`).orderByKey();
    todoItemsTdy.on('value', (items) => {
      const newState = [];
      const todoItems = items.val();
      for (const todoItem in todoItems) {
        newState.push({
          id: todoItem,
          text: todoItems[todoItem].text,
          date: todoItems[todoItem].date,
          textClass: todoItems[todoItem].textClass,
        });
      }
      this.setState({ todoTdy: newState });
    });

    const todoItemsTmr = firebase.db.ref(`todoItems/${authUser.uid}/tmr/`).orderByKey();
    todoItemsTmr.on('value', (items) => {
      const newState = [];
      const todoItems = items.val();
      for (const todoItem in todoItems) {
        newState.push({
          id: todoItem,
          text: todoItems[todoItem].text,
          date: todoItems[todoItem].date,
          textClass: todoItems[todoItem].textClass,
        });
      }
      this.setState({ todoTmr: newState });
    });
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleShow() {
    this.setState({ open: true });
  }

  archive() {
    const { firebase, authUser, changeOneCompleted } = this.props;
    const { todoTdy, todoTmr } = this.state;
    const tdy = todoTdy;
    const tmr = todoTmr;
    const todoItems = tdy.concat(tmr);
    let numberOfCompleted = 0;
    for (let i = 0; i < todoItems.length; i++) {
      if (todoItems[i].textClass === 'completed') {
        firebase.db.ref(`todoItems/${authUser.uid}/archive/`).push(todoItems[i]);
        firebase.db.ref(`todoItems/${authUser.uid}/tdy/${todoItems[i].id}`).remove();
        firebase.db.ref(`todoItems/${authUser.uid}/tmr/${todoItems[i].id}`).remove();
        numberOfCompleted += 1;
      }
    }
    const N = todoItems.length;
    if (N - numberOfCompleted === 0) {
      this.setState({ text: 'Thank you for completing all the tasks of the day ^^' });
    } else if (numberOfCompleted === 0) {
      this.setState({ text: 'You know how to press buttons xP' });
    } else {
      this.setState({ text: 'You have completed some tasks, good start ;)' });
    }
    this.handleShow();
    changeOneCompleted(false);
  }


  render() {
    const {
      open, text,
    } = this.state;
    const { todoListReducer } = this.props;
    return (
      <div className="pt-3 pb-3">
        <button
          data-tip
          data-for="tooltipArchive"
            // value={this.props.todoItems.length * 0.13}
          onClick={() => this.archive()}
          className={`myArchiveButton ${!todoListReducer.oneCompleted && 'hidden'}`}
        >
          <FontAwesomeIcon icon={faShare} />
        </button>
        <ReactTooltip id="tooltipArchive">
              Press to send completed tasks to the archive.
        </ReactTooltip>
        <Modal
          open={open}
          onClose={this.handleClose}
          little
        >
          <h2>Bravo !!!</h2>
          <h4>Good job !</h4>
          <p>{text}</p>
        </Modal>
      </div>
    );
  }
}

Archive.propTypes = {
  firebase: PropTypes.objectOf(PropTypes.object),
  authUser: PropTypes.objectOf(PropTypes.object),
  todoListReducer: PropTypes.shape({
    oneCompleted: PropTypes.bool,
  }).isRequired,
};

Archive.defaultProps = {
  firebase: {},
  authUser: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(Archive);
