import React from 'react';
import { Link } from 'react-router-dom';
import {
  Col, Form, Grid, Row,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as ROUTES from '../../constants/routes';
import ListExample from './listExample';
import todoExampleActions from '../../redux/actions/todoExampleActions';

const mapStateToProps = (stateRedux) => ({
  ...stateRedux,
});

const mapDispatchToProps = (dispatch) => ({
  addTodo: (todo) => todoExampleActions.addTodo(dispatch, todo),
});

class IntroPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      todoItemsTdy: [],
      textClass: 'simple',
      showDiv: '',
      showToEdit: 'hidden',
      text: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // To create a new to-do :
  handleChange(e, key) {
    const newState = {};
    newState[key] = e.target.value;
    this.setState(newState);
  }

  handleSubmit(e) {
    e.preventDefault();
    const {
      text, textClass, showToEdit, showDiv, todoItemsTdy,
    } = this.state;
    if (!text.length) {
      return;
    }
    const newItem = {
      text,
      textClass,
      showToEdit,
      showDiv,
    };
    todoItemsTdy.push(newItem);
    this.setState({ text: '' });
    this.props.addTodo(newItem);
  }

  render() {
    const { text, todoItemsTdy } = this.state;
    return (
      <div>
        <Grid>
          <Row className="textBigC">
            This is the TOday and TOmorrow App.
          </Row>
          <Row className="textC">
            On this page you can try it. Once you reload the page, all changes will be gone.
          </Row>
          <Row className="textC">
            {'If you want to use it fully, just '}
            <Link to={ROUTES.SIGN_IN} className="link">
              {'sign in'}
            </Link>
            {'! If you don\'t have an account yet, you are welcome to '}
            <Link to={ROUTES.SIGN_UP} className="link">
              {'sign up'}
            </Link>
            {' :) '}
          </Row>
          <Row className="tc pt-2 pb-2">
          </Row>
          <Row className="bothLists ">
            <Col md={6} className="boxShadowTdy">
              <h1>TODAY</h1>
              <ListExample
                day="tdy"
                hidden={false}
              />
            </Col>
            <Col md={6} className="boxShadowTmr">
              <h1 className="textWhite">TOMORROW</h1>
              <ListExample
                day="tmr"
                hidden
              />
            </Col>
          </Row>
          <Row>
            <Form onSubmit={(e) => this.handleSubmit(e)} className="w-100" style={{ maxWidth: '100%' }}>
              <Grid>
                <Row className="pt5 itemRow">
                  <Col md={1}>
                    <h5 className="tl">
                                  New:
                    </h5>
                  </Col>
                  <Col md={5}>
                    <input
                      id="new-todo"
                      className="inputItem"
                      onChange={(e) => this.handleChange(e, 'text')}
                      value={text}
                    />
                  </Col>
                  <Col md={3}>
                    <button
                      className="mySubmitButton"
                      type="submit"
                    >
                      {'Task '}
                      {todoItemsTdy.length + 1}
                    </button>
                  </Col>
                  <Col md={3} />
                </Row>
              </Grid>
            </Form>

          </Row>
        </Grid>
      </div>

    );
  }
}

IntroPage.propTypes = {
  addToDo: PropTypes.func,
};

IntroPage.defaultProps = {
  addToDo: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(IntroPage);
