import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Alert} from "react-bootstrap";
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const PasswordForgetPage = () => (
  <div>
    <h1>PasswordForget</h1>
    <PasswordForgetForm />
  </div>
);

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      error: null,
      show: 'hidden',
    };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({
          email: '',
          error: null,
          show: 'show',
        });
      })
      .catch((error) => {
        this.setState({ error });
      });

    event.preventDefault();
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    const { email, error } = this.state;

    const isInvalid = email === '';

    return (
      <div className="forgetPasswordBase">
        <h2 className="pt4">Get a link to reset your password:</h2>
        <form onSubmit={this.onSubmit}>
          <div className="myRow">
            <span className="myCol-25 textRight">
              Your email:
            </span>
            <span className="myCol-50">
              <input
                name="email"
                className="inputItem"
                type="text"
                placeholder="Your email here"
                value={email}
                onChange={this.onChange}
              />
            </span>
            <span className="myCol-25 textCenter">
              <button
                className="mySubmitButton pointer"
                type="submit"
                disabled={isInvalid}
              >
                Confirm
              </button>
            </span>
          </div>

          <div className="myRow textCenter">
            {error
                    && (
                    <div>
                      <Alert bgStyle='danger'>{error.message}</Alert>
                      <div />
                    </div>
                    )}
          </div>
          <div className={this.state.show}>
            <Alert bsStyle='success'>
              An email has been sent to you.
            </Alert>
          </div>
        </form>
      </div>
    );
  }
}

const PasswordForgetLink = () => (
  <div className="myRow textCenter">
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </div>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
