import reduxGlossary from '../glossary';

const todoExampleReducer = (state = {}, action) => {
  switch (action.type) {
    case reduxGlossary.actionName.todoExample.addTodo:
      state.todoItemsTdy.push(action.payload);
      return {
        ...state,
      };
    case reduxGlossary.actionName.todoExample.deleteTodo:
      state[action.payload.listId].splice(action.payload.index, 1);
      return {
        ...state,
      };
    case reduxGlossary.actionName.todoExample.moveTodo:
      state[action.payload.list1Id].push(action.payload.todo);
      state[action.payload.list2Id].splice(action.payload.index, 1);
      return {
        ...state,
      };
    case reduxGlossary.actionName.todoExample.doneTodo:
      if (state[action.payload.listId][action.payload.index].textClass === 'simple') {
        state[action.payload.listId][action.payload.index].textClass = 'completed';
      } else {
        state[action.payload.listId][action.payload.index].textClass = 'simple';
      }
      return {
        ...state,
      };
    case reduxGlossary.actionName.todoExample.editTodo:
      state[action.payload.listId][action.payload.index].showDiv = 'hidden';
      state[action.payload.listId][action.payload.index].showToEdit = 'text';
      return {
        ...state,
      };
    case reduxGlossary.actionName.todoExample.editedTodo:
      state[action.payload.listId][action.payload.index].showDiv = '';
      state[action.payload.listId][action.payload.index].showToEdit = 'hidden';
      state[action.payload.listId][action.payload.index].text = action.payload.text;
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default todoExampleReducer;
