import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './redux/store';

import * as serviceWorker from './serviceWorker';
import App from './components/App';
import Firebase, { FirebaseContext } from './components/Firebase';

import './index.css';
import './buttons.css';
import './stylesForAll.css';
import './forResponsive.css';
import './backgrounds.css';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/tachyons/css/tachyons.css';

ReactDOM.render(
  <Provider store={configureStore()}>
    <FirebaseContext.Provider value={new Firebase()}>
      <App />
    </FirebaseContext.Provider>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
