import React from 'react';
import { Grid, Col, Row } from 'react-bootstrap';
import { AuthUserContext, withAuthorization } from '../Session';
import { FirebaseContext } from '../Firebase';
import AddToDo from './addtodo';
import List from './list';
import Archive from './archive';
import History from './history';
import './todolist.css';

class HomePage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
    };
    this.getPrettyDate = this.getPrettyDate.bind(this);
    this.getPrettyTime = this.getPrettyTime.bind(this);
  }

  // help to create an ID :
  getPrettyDate(Date) { // sep = separator
    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thr', 'Fri', 'Sat'];
    return `${Date.getFullYear()} - ${(Date.getMonth() < 10 ? '0' : '')}${Date.getMonth() + 1} - ${(Date.getDate() < 10 ? '0' : '') + Date.getDate()}, ${weekdays[Date.getDay()]}`;
  }

  getPrettyTime(Date) {
    const Hour = Date.getHours() < 10 ? `0${Date.getHours()}` : Date.getHours();
    const Minute = Date.getMinutes() < 10 ? `0${Date.getMinutes()}` : Date.getMinutes();
    const Second = Date.getSeconds() < 10 ? `0${Date.getSeconds()}` : Date.getSeconds();
    return `${Hour}:${Minute}:${Second}`;
  }

  render() {
    const setName = (firebase, authUser) => firebase.db.ref(`users/${authUser.uid}/username`)
      .on('value', (e) => {
        this.setState({ name: e.val() });
      });
    return (
      <FirebaseContext.Consumer>
        {(firebase) => (
          <AuthUserContext.Consumer>
            {(authUser) => (
              <Grid className="appWidth">
                <Row className="tc pt-4 pb-3 centering">
                  <h2>
                    {'Hello, '}
                    {setName(firebase, authUser)}
                    {this.state.name}
                    {' !'}
                  </h2>
                </Row>
                <Row className="tc pt-2 pb-2 centering">
                  <h3>
                    So, what is your plan for today? :)
                  </h3>
                </Row>
                <Row className="centering">
                  <Col md={6} className="tc historyText">
                    <h2>{this.getPrettyDate(new Date())}</h2>
                  </Col>
                  <Col md={3} className="tr">
                    <Archive
                      firebase={firebase}
                      authUser={authUser}
                    />
                  </Col>
                  <Col md={3} className="tl">
                    <History
                      firebase={firebase}
                      authUser={authUser}
                    />
                  </Col>
                </Row>
                <Row className="bothLists ">
                  <Col md={6} className="boxShadowTdy">
                    <h1>TODAY</h1>
                    <List
                      firebase={firebase}
                      authUser={authUser}
                      day="tdy"
                      getPrettyDate={this.getPrettyDate}
                      getPrettyTime={this.getPrettyTime}
                      hidden={false}
                    />
                  </Col>
                  <Col md={6} className="boxShadowTmr">
                    <h1 className="textWhite">TOMORROW</h1>
                    <List
                      firebase={firebase}
                      authUser={authUser}
                      day="tmr"
                      getPrettyDate={this.getPrettyDate}
                      getPrettyTime={this.getPrettyTime}
                      hidden
                    />
                  </Col>
                </Row>
                <Row>
                  <AddToDo
                    firebase={firebase}
                    authUser={authUser}
                    day="tdy"
                    getPrettyDate={this.getPrettyDate}
                    getPrettyTime={this.getPrettyTime}
                  />
                </Row>
              </Grid>
            )}
          </AuthUserContext.Consumer>
        )}
      </FirebaseContext.Consumer>
    );
  }
}

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(HomePage);
