import React, { Component } from 'react';
import {Alert} from 'react-bootstrap';
import { withFirebase } from '../Firebase';


class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      passwordOne: '',
      passwordTwo: '',
      error: null,
      show: 'hidden',
    };
  }

  onSubmit = event => {
    const { passwordOne } = this.state;

    this.props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        this.setState({
          error: null,
          show: 'show',
        });
      })
      .catch((error) => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { passwordOne, passwordTwo, error } = this.state;

    const isInvalid = passwordOne !== passwordTwo || passwordOne === '';

    return (
      <form onSubmit={this.onSubmit}>
        <h2 className="">Manually reset your password:</h2>
        <div className="myRow">
          <span className="myCol-25 textRight">New password:</span>
          <span className="myCol-50">
            <input
              name="passwordOne"
              className="inputItem"
              value={passwordOne}
              onChange={this.onChange}
              type="password"
              placeholder=" "
            />
          </span>
        </div>
        <div className="myRow">
          <span className="myCol-25 textRight">Repeat password:</span>
          <span className="myCol-50">
            <input
              name="passwordTwo"
              className="inputItem"
              value={passwordTwo}
              onChange={this.onChange}
              type="password"
              placeholder=" "
            />
          </span>
          <span className="myCol-25 textCenter">
            <button
              className="mySubmitButton pointer "
              disabled={isInvalid}
              type="submit"
            >
          Reset
            </button>
          </span>
        </div>
        <div className="myRow textCenter">
          {error
              && (
              <div>
                <Alert bgStyle='danger'>{error.message}</Alert>
                <div />
              </div>
              )}
        </div>
        <div className="myRow textCenter">
              <div className={this.state.show}>
                <Alert bsStyle='success'>
                  Your password is successfully changed.
                </Alert>
              </div>
        </div>
      </form>
    );
  }
}

export default withFirebase(PasswordChangeForm);
