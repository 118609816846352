import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Row, FormControl, Form,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import todoExampleActions from '../../redux/actions/todoExampleActions';

const mapStateToProps = (stateRedux) => ({
  ...stateRedux,
});

const mapDispatchToProps = (dispatch) => ({
  deleteTodo: (list, i) => todoExampleActions.deleteTodo(dispatch, list, i),
  moveTodo: (list1, todoItem, list2, i) => todoExampleActions.moveTodo(dispatch, list1, todoItem, list2, i),
  doneTodo: (list, i) => todoExampleActions.doneTodo(dispatch, list, i),
  editTodo: (list, i) => todoExampleActions.editTodo(dispatch, list, i),
  editedTodo: (list, i, newText) => todoExampleActions.editedTodo(dispatch, list, i, newText),
});

class ListExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      todoItems: [],
      newText: '',
      text: '',
    };

    this.done = this.done.bind(this);
    this.move = this.move.bind(this);
    this.editText = this.editText.bind(this);
    this.textEdited = this.textEdited.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {
    const { todoExampleReducer, day } = this.props;
    if (day === 'tdy') {
      const newState = todoExampleReducer.todoItemsTdy;
      this.setState({ todoItems: newState });
    } else {
      const newState = todoExampleReducer.todoItemsTmr;
      this.setState({ todoItems: newState });
    }
  }

  // To create a new to-do :
  handleChange(e, key) {
    const newState = {};
    newState[key] = e.target.value;
    this.setState(newState);
  }


  // functions to operate with todos :

  deleteItem(i) {
    const { deleteTodo, day } = this.props;
    if (day === 'tdy') {
      deleteTodo('todoItemsTdy', i);
    } else {
      deleteTodo('todoItemsTmr', i);
    }
  }

  move(movedItem, i) {
    const { day, moveTodo } = this.props;
    if (day === 'tdy') {
      moveTodo('todoItemsTmr', movedItem, 'todoItemsTdy', i);
    } else {
      moveTodo('todoItemsTdy', movedItem, 'todoItemsTmr', i);
    }
  }

  done(i) {
    const { doneTodo, day } = this.props;
    if (day === 'tdy') {
      doneTodo('todoItemsTdy', i);
    } else {
      doneTodo('todoItemsTmr', i);
    }
  }


  editText(i) {
    const { todoItems } = this.state;
    const { editTodo, day } = this.props;
    if (day === 'tdy') {
      editTodo('todoItemsTdy', i);
    } else {
      editTodo('todoItemsTmr', i);
    }
    this.setState({ newText: todoItems[i].text });
  }

  textEdited(i) {
    const { newText } = this.state;
    const { editedTodo, day } = this.props;
    if (day === 'tdy') {
      editedTodo('todoItemsTdy', i, newText);
    } else {
      editedTodo('todoItemsTmr', i, newText);
    }
  }

  render() {
    const { todoItems, newText, text } = this.state;
    const { hidden } = this.props;
    return (
      todoItems.map((todoItem, index) => (

        <Grid key={index * 0.2345} className={todoItem.textClass}>
          <Row className="itemRow">
            <button
              onClick={() => this.move(todoItem, index)}
              className="moveButton moveButtonLeft"
              hidden={!hidden}
            >
              {'❰'}
            </button>
            <button
              data-tip
              data-for="tooltipCompleted"
              onClick={() => this.done(index)}
              className="listButton"
            >
              {index + 1}
            </button>
            <ReactTooltip id="tooltipCompleted">
                  Press to if the task is completed.
            </ReactTooltip>
            <div className="itemText">
              <div
                data-tip
                data-for="tooltipCorrect"
                onDoubleClick={() => this.editText(index)}
                className={todoItem.showDiv}
              >
                {todoItem.text}
              </div>
            </div>
            <div
              className={`todoChangeForm ${todoItem.showToEdit}`}
              onMouseLeave={() => this.textEdited(index)}
            >
              <Form className="itemText ">
                <FormControl
                  id={(todoItems.length / 123).toString()}
                  type="text"
                  value={newText}
                  onChange={(e) => this.handleChange(e, 'newText')}
                />
              </Form>
            </div>
            <ReactTooltip id="tooltipCorrect">
                  Double-click to correct the task.
            </ReactTooltip>
            <button
              data-tip
              data-for="tooltipDelete"
              onClick={() => this.deleteItem(index)}
              className="deleteButton"
            >
                  X
            </button>
            <ReactTooltip id="tooltipDelete">
                  Press to delete the task.
            </ReactTooltip>
            <button
              onClick={() => this.move(todoItem, index)}
              className="moveButton moveButtonRight"
              hidden={hidden}
            >
              {'❱'}
            </button>
          </Row>
        </Grid>
      ))
    );
  }
}

ListExample.propTypes = {
  day: PropTypes.string,
  hidden: PropTypes.bool,
  todoExampleReducer: PropTypes.shape({
    todoItemsTdy: PropTypes.objectOf(PropTypes.object),
    todoItemsTmr: PropTypes.objectOf(PropTypes.object),
  }).isRequired,
  addTodo: PropTypes.func,
  deleteTodo: PropTypes.func,
  moveTodo: PropTypes.func,
  doneTodo: PropTypes.func,
  editTodo: PropTypes.func,
  editedTodo: PropTypes.func,
};

ListExample.defaultProps = {
  day: 'tdy',
  hidden: true,
  addTodo: () => {},
  deleteTodo: () => {},
  moveTodo: () => {},
  doneTodo: () => {},
  editTodo: () => {},
  editedTodo: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(ListExample);
